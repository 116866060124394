@import "styles/color.module";


$breakpoint-mobile: 999999px;
$breakpoint-tablet: 999999px;
$breakpoint-desktop: 999999px;
$mobileMaxWidth: 600px;
$topBarHeight: 80px;
$topBarHeightMobile: 50px;

$layoutLeftPadding: 20px;
$layoutRightPadding: 20px;

$layoutLeftPaddingNew: 16px;
$layoutRightPaddingNew: 16px;


@mixin mobile {
    @media (max-width: #{$breakpoint-mobile - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$breakpoint-tablet - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$breakpoint-desktop - 1px}) {
        @content;
    }
}

.heading2Xl {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
}

.headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
}

.headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
}

.borderCircle {
    border-radius: 9999px;
}

.colorInherit {
    color: inherit;
}

.padding1px {
    padding-top: 1px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.listItem {
    margin: 0 0 1.25rem;
}

.lightText {
    color: #666;
}

.testButton {
    background-color: silver;
    padding: 4px 8px;
    margin: 3px 3px;
    width: fit-content;
}

.testProduct {
    background-color: lightpink;
    padding: 12px 20px;
    margin: 3px 3px;
    width: fit-content;
    display: inline-block;
}

.cursorPointer {
    cursor: pointer;
}

.cursorUnset {
    cursor: unset !important;
}

.block {
    display: block;
}

.inlineBlock {
    display: inline-block!important;
}

.fullWidth {
    width: 100%;
}

.fullFlex {
    flex: 1;
}

.verticalMiddle {
    vertical-align: middle;
}

.justifyContentStart {
    display: flex;
    justify-content: flex-start;
}

.justifyContentCenter {
    display: flex;
    justify-content: center;
}

.justifyContentSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.justifyContentEnd {
    display: flex;
    justify-content: flex-end;
}

.alignItemsCenter {
    display: flex;
    align-items: center;
}

//.alignItemsStretch {
//    display: flex;
//    align-items: stretch;
//}

.alignItemsStart {
    display: flex;
    align-items: flex-start !important;
}

.alignItemsEnd {
    display: flex;
    align-items: flex-end;
}

.rootContainer {
    max-width: $mobileMaxWidth;
    //padding-top: $topBarHeightMobile;
    width: 100%;
    margin: auto;
    flex: 1;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.flexCol {
    display: flex;
    flex-direction: column;
}

.flexFit {
    flex: 1;
}

.container {
    width: 100%;
    position: relative;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.displayNone {
    display: none;
}

.square {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;
    //border-radius: 5px;
    overflow: hidden;
    @extend .no-select;
    isolation: isolate;
}

.noBorderRadius {
    border-radius: 0;
}


.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.borderUnderLine {
    border-bottom: 1px solid $border;
}

.borderLine2 {
    margin : 12px 0;
    border-bottom: 1px solid $gray200;
}

.borderUnderLineThick {
    border-bottom: 2px solid $border;
}

.mobileBorder {
    background-color: $border;
    height: 10px;
}

.tableBorderUnderLine {
    border-bottom: 1px solid $tableBorder;
}

$topPadding: 40px;
$topPaddingMobile: 16px;

.txtTitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: $typo4;
    display: inline-block;
    width: 100%;
}

.bold {
    font-weight: bold;
}

.semiBold {
    font-weight: 600;
}

.txtIndent {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $typo2;
    display: inline-block;
    width: 100%;
    margin-top: 8px;
    padding-left: 16px;
    word-break: keep-all;
    word-wrap: break-word;
}

.txtSmallTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: $typo4;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 4px;
    width: 100%;
}

.txtSubTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: $typo4;
    display: inline-block;
    margin-top: 40px;
    width: 100%;
}

.txtPlain {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $typo2;
    margin-top: 8px;
    width: 100%;
    display:flex;
    word-break: keep-all;
    word-wrap: break-word;
}

.bullet {
    margin-right: 10px;
    margin-left: 8px;
    display: inline-block;
}

.invisible {
    visibility: hidden;
}

.pageTitle {
    font-weight: bold;
    line-height: 100%;
    font-size: 26px;
    color: $typo4;
    display: block;
}

@include tablet {
    .pageTitle {
        font-size: 22px;
    }
}

@include mobile {
    .pageTitle {
        font-size: 20px;
    }
}

.sidePadding {
    padding-left: 0;
    padding-right: 0;
}

.newSidePadding {
    padding-left: $layoutLeftPaddingNew;
    padding-right: $layoutRightPaddingNew;
}

.whiteBackground {
    background-color: white;
}

.sideMargin {
    margin-left: 0;
    margin-right: 0;
}

@include tablet {
    .sidePadding {
        padding-left: $layoutLeftPadding;
        padding-right: $layoutRightPadding;
    }

    .sideMargin {
        margin-left: $layoutLeftPadding;
        margin-right: $layoutRightPadding;
    }
}

$navBarWidthDesktop: 280px;
$navBarWidthTablet: 200px;
$mobilNavBarHeight: 62px;

$mobilStoreNavBarHeight: 40px;

//$topBannerHeight: 70px;
$topBannerHeight: 40px;
$topBannerWebHeight: 60px;


$topTabBarHeightDesktop: 56px;
$topTabBarHeightTablet: 56px;
$topTabBarHeightMobile: 56px;

$newTopTabBarHeightDesktop: 49px;
$newTopTabBarHeightTablet: 48px;
$newTopTabBarHeightMobile: 39px;

:export {
    breakpointMobile: $breakpoint-mobile;
    breakpointTablet: $breakpoint-tablet;
    breakpointDesktop: $breakpoint-desktop;
    topBarHeight: $topBarHeight;
    mobilStoreNavBarHeight: $mobilStoreNavBarHeight;
    topPadding: $topPadding;
    topBannerHeight: $topBannerHeight;
    topTabBarHeight: $topTabBarHeightDesktop;
    layoutLeftPaddingNew: $layoutLeftPaddingNew;
    layoutRightPaddingNew: $layoutRightPaddingNew;
}

.fullHeight {
    height: 100%;
}

.containerElevated {
    bottom: $mobilNavBarHeight !important;
    transition: bottom 0.1s ease;
}

.noLineHeight {
    line-height: 0 !important;
}

.noMargin {
    margin: 0;
}

.fullLineHeight {
    line-height: 100% !important;
}

.mainTitle {
    font-weight: bold;
    line-height: 100%;
    font-size: 28px;
    color: $typo4;
    margin-bottom: 40px;
    margin-top: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.brandColorText {
    color: $brand !important;
}

.hide {
    display: none !important;
}

$pageBottomMarginDesktop: 140px;
$pageBottomMarginTablet: 100px;
$pageBottomMarginMobile: 60px;

.pageContainer {
    margin-bottom: $pageBottomMarginDesktop;
}

@include tablet {
    .pageContainer {
        margin-bottom: $pageBottomMarginTablet;
    }
}

@include mobile {
    .pageContainer {
        margin-bottom: $pageBottomMarginMobile;
    }
}

.transparent {
    opacity: 0;
}

.textAlignCenter {
    text-align: center;
}

.topSticky {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 100;
}

.topFixed {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
}

.socialIcon {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 18px;
    position: absolute;
    @extend .flexRow;
    @extend .alignItemsCenter;
}

.stickBottom {
    background-color: white;
    position: sticky;
    left: 0;
    bottom: 0 !important;
    z-index: 10001;
    margin-top: 200px;
    padding: 13px 16px calc(5px + env(safe-area-inset-bottom)) 16px;
}

.transparentButton {
    background: none;
    border: none;
}

.transparentChildButton > button {
    background: none;
    border: none;
}

.medium {
    font-weight: 500;
}

.errorText {
    color: red;
}
//
//$fpx: 1.6px;
//
//@media (max-width: 600px) {
//    $fpx: 0.266vw !important;
//}
