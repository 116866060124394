@import "styles/color.module";
@import "styles/utils.module";

.pageContainer{
  @extend .pageContainer;
  position: relative;
}

.section {
  padding: 20px 16px;
  margin-bottom: 0;
}

.sectionTitleWrapper {
  display: flex;
  flex-direction: row;
  @extend .justifyContentSpaceBetween;
}

.sectionTitle {
  font-size: 26px;
  font-weight: bold;
  line-height: 100%;
  color: $typo4;
}

@include tablet {
  .sectionTitle {
    font-size: 20px;
  }
}

.row {
  @extend .flexRow;
  margin-top: -4px;
  //overflow-x: auto;
  flex-wrap: wrap;
}

.col {
  margin-top: 24px;
  height: auto;
}

.twoColumnProductsRow {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.twoColumnProductsCol {
  padding: 0 4.5px;
  flex: 0 0 50%;
}

.threeColumnProductsRow {
  margin-left: -5px;
  margin-right: -5px;
}

.threeColumnProductsCol {
  padding: 0 5px;
  flex: 0 0 33.3%;
}

.feedList {
  margin: 24px -20px 0 -20px;
}

.postCol {
  padding: 0 20px 40px 20px;
}

@include tablet {

  .feedList {
    margin: 24px -5px 0 -5px;
  }
  .postCol {
    padding: 0 5px 10px 5px;
  }
}

@include mobile {
  .feedList {
    margin: 0 -2.5px 0 -2.5px;
  }
  .postCol {
    padding: 0 2.5px 10px 2.5px;
  }
}

.specialOfferRow {
  @extend .flexRow;
  margin-left: -$layoutLeftPadding;
  margin-right: -$layoutRightPadding;
  margin-top: -36px;
  //overflow-x: auto;
  flex-wrap: wrap;
}

.specialOfferCol {
  padding: 0 20px;
  flex: 0 0 25%;
  height: auto;
  margin-top: 60px;
}

@include tablet {
  .specialOfferRow {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -16px;
  }
  .specialOfferCol {
    padding: 0 8px;
    flex: 0 0 50%;
    margin-top: 40px;
    //&:last-of-type {
    //  padding-right: 0;
    //}
  }
}

@include mobile {
  .specialOfferRow {
    margin-left: -4.5px;
    margin-right: -4.5px;
    margin-top: -4px;
  }
  .specialOfferCol {
    padding: 0 4.5px;
    flex: 0 0 50%;
    margin-top: 24px;
    //&:last-of-type {
    //  padding-right: 0;
    //}
  }
}

.bannerContainer {
  padding: 0 20px 24px 20px;
}

.bannerWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 16.71%;
  border-radius: 5px;
  overflow: hidden;
}

.shortcutBannerImage {
  width: 100%;
  padding-top: 16.327%;
  height: 0;
  position: relative;
  margin-top: 28px;
  @extend .cursorPointer;
}

.midBannerContainer {
  width: 100%;
  position: relative;
  padding: 7px 0 16px;
}

.midBannerWrapper {
  overflow: hidden;
}
