@import "styles/color.module";
@import "styles/utils.module";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $brand;

  border-radius: 9999px;
  padding: 6px 10px;
}

.content {
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #FFFFFF;

}
