@import "styles/utils.module";

.root {
  position: relative;
  width: 100%;
  margin-top: -50px;
}

.swiperWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.verticalSwiperWrapper {
  position: relative;
  left: 0;
  right: 0;
  display: block;
}

.swiper {
  height: 100%;
}

.small {
  padding-top: 16.37%;
}

.bannerImage {
  height: 100%;
}

.arrowLeft {
  position: absolute;
  right: 9.02%;
  margin-right: 56px;
  bottom: 10.0%;
  z-index: 99;
  @extend .cursorPointer;
}

.arrowRight {
  position: absolute;
  right: 9.02%;
  bottom: 10.0%;
  z-index: 99;
  @extend .cursorPointer;
}

.indicator {
  width: 40px;
  height: 4px;
  background-color: white;
  display: inline-block;
  @extend .cursorPointer;
}

.indicatorSelected {
  background-color: rgba($tableBorder, 0.7);
}

.bannerItem {
}

.verticalImage{
  width: 100%;
  display: block;
}

.horizontalImage{
  width: 100%;
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(25, 25, 25, 0.2) 0%, rgba(25, 25, 25, 0) 57.81%);
}

// need check mobile banner gradient
@include mobile {
  .overlay {
    background: linear-gradient(0deg, rgba(25, 25, 25, 0.3) 0%, rgba(25, 25, 25, 0) 100%);
  }
}

.bannerContainer {
  //padding-bottom: 80px;
  margin-top: 60px - $topPadding;
}

@include tablet {
  .bannerContainer {
    margin-top: 42px - $topPadding;
    //padding-bottom: 40px;
    padding-left: $layoutLeftPadding;
    padding-right: $layoutRightPadding;
  }
}


@include mobile {
  .bannerContainer {
    padding-left: 0;
    padding-right: 0;
    //padding-bottom: 20px;
  }
}

.tallMainBannerBottomContainer {
  position: absolute;
  padding-left: 24px;
  padding-bottom: 32px;
  left: 0;
  bottom: 0;
  //transform: translateY(-69%);
  font-size: 24px;
  width: 100%;
  background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.7) 100%);
}

.tallMainBannerTextContainer {
  max-width: 85%;
  display: flex;
  flex-direction: column;
}

.labelContainer {
  display: flex;
  & > *+* {
    margin-left: 6px;
  }
  margin-bottom: 4px;
}

.tallMainBannerSubText {
  font-size: 16px;
  color: white;
  font-weight: 500;
  line-height: 120%;
  margin-top: 8px;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.tallMainBannerTitle {
  font-size: 24px;
  color: white;
  font-weight: 700;
  line-height: 140%;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.textContainer {
  position: absolute;
  left: 60px;
  top: 48%;
  transform: translateY(-50%);
  font-size: 40px;
}


@mixin bannerPCBreakpoint {
  //@media (max-width: #{1200px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}

@mixin bannerTabletBreakpoint {
  //@media (max-width: #{892px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}


@mixin bannerMobileBreakpoint {
  //@media (max-width: #{454px}) {
  @media (max-width: #{999999px}) {
    @content;
  }
}



.bannerText {
  white-space: pre-wrap;
  display: block;
  line-height: 130%;
  font-size: 36px;
  //font-size: 40px;
  font-weight: 700;
  color: white;
  letter-spacing: -0.0075em;
}

@include desktop {
  .bannerText {
    font-size: 2.5vw;
  }
}

.bannerSubText {
  display: block;
  font-size: 16px;
  //font-size: 1vw;
  font-weight: 500;
  line-height: 100%;
  color: white;
  margin-top: 16px;
}

.bannerTitle {
  position: absolute;
  top: 18%;
  left: 60px;
  font-size: 17px;
  font-weight: bold;
  line-height: 100%;
  color: white;
}

.swiperHorizontal {
  position: absolute;
  left: auto !important;
  right: 16px !important;
  bottom: 32px !important;
  //transform: translateY(50%);
  z-index: 10;
  @extend .flexRow;
  width: auto !important;
  @extend .cursorPointer;
}

.hiddenSwiper {
  display: none;
}

.paginationIndicator {
  width: 34px !important;
  background-color: rgba($gray800, 0.60);
  padding: 4px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 16px;
  color: $border;
  display: inline-block;
}

.paginationSeeMoreButton {
  border-radius: 9999px;
  background-color: $brand;
  width: 20px;
  height: 20px;
  margin-left: 6px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  & path {
    fill: white;
  }
}

.swiperCurrent {
  color: white;
}

.swiperBullet {
  display: inline-block;
  width: 40px;
  height: 3px;
  background-color: rgba($typo4, 0.35);
}

.swiperBulletActive {
  background-color: white;
}

.buttonControllerContainer {
  position: absolute;
  right: 60px;
  bottom: 32px !important;
  transform: translateY(50%);
  z-index: 10;
  @extend .flexRow;
}

.swiperPrevButton {
  margin-right: 24px;
  @extend .cursorPointer;
}

.swiperNextButton {
  @extend .cursorPointer;
}

@include bannerPCBreakpoint {
  .bannerTitle {
    font-size: 15px;
  }

  .textContainer {
    left: 48px;
  }

  .bannerTitle {
    left: 48px;
  }

  .bannerSubText {
    font-size: 14px;
  }

  //.swiperHorizontal {
  //  left: 48px !important;
  //}

  .buttonControllerContainer {
    right: 48px;
  }
}



@include tablet {
  .textContainer {
    left: 36px;
  }

  .buttonControllerContainer {
    right: 36px;
  }

  .bannerTitle {
    top: 17%;
    font-size: 16px;
    left: 36px;
  }

  //.swiperHorizontal {
  //  left: 36px !important;
  //}

  .bannerText {
    font-size: 2.8vw;
  }

  .bannerSubText {
    margin-top: 12px;
    font-size: 14px;
  }
}

@include bannerTabletBreakpoint {
  .textContainer {
    top: 49%;
  }

  .bannerTitle {
    font-size: 14px;
  }

  .bannerSubText {
    margin-top: 8px;
    font-size: 13px;
  }
}

@include mobile {
  //.swiperHorizontal {
  //  bottom: 26px !important;
  //  left: 50% !important;
  //  //transform: translateX(-50%) !important;
  //  width: 320px !important;
  //}

  .textContainer {
    top: unset;
    bottom: 32px;
    left: 20px;
    transform: none;
  }

  .bannerSubText {
    margin-top: 20px;
  }

  .bannerText {
    font-size: 32px;
    line-height: 140%;
  }

  .bannerTitle {
    left: 20px;
    bottom: 218px;
    top: unset;
    font-size: 16px;
  }
}

@include bannerMobileBreakpoint {
  .bannerSubText {
    font-size: 15px;
    line-height: 100%;
    margin-top: 12px;
  }

  .bannerText {
    font-size: 26px;
  }

  .bannerTitle {
    bottom: 155px;
  }
}

//.swiperHorizontal {
//  text-align: center !important;
//  position: relative !important;
//  bottom: unset !important;
//  top: 100% !important;
//  width: 100%;
//  margin-top: 15px;
//  line-height: 0 !important;
//}
//
//.swiperBullet {
//  margin-right: 0 !important;
//  background-color: $tableBorder !important;
//  border-radius: 9999px !important;
//  height: 4px !important;
//  width: 4px !important;
//  bottom: 1px;
//  display: inline-block !important;
//  margin-left: 4px !important;
//  line-height: 0 !important;
//  position: relative;
//  @extend .cursorPointer;
//}
//
//.swiperBulletActive {
//  bottom: 0;
//  background-color: rgba($brand, 0.35) !important;
//  width: 6px !important;
//  height: 6px !important;
//  @extend .justifyContentCenter;
//  @extend .alignItemsCenter;
//  &::after {
//    content: "";
//    background-color: $brand;
//    position: absolute;
//    top: 1px;
//    left: 1px;
//    width: 4px;
//    height: 4px;
//    border-radius: 9999px;
//    display: block;
//  }
//}
//

.paginationIndicatorWrapper {
  @extend .flexRow;
}

.placeholder {
  background-color: $gray300;
}

.imageOverlay {
  background: rgba(#000, 0.2);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageWrapper {
}
