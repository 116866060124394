@import "styles/color.module";
@import "styles/utils.module";

.newBadge {
  width: 15px;
  height: 15px;
  background-color: $brand;
  border-radius: 9999px;
  position: absolute;
  top: 0;
  right: 1.5px;
  color: white;
  font-size: 9px !important;
  font-weight: 900;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.shortcutWrapper {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  width: 100%;
}

.shortcutContainer {
  @extend .flexCol;
  flex: 1;

}

.shortcut {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  width: 54px;
}

.shortcutRow {
  @extend .flexRow;
  justify-content: space-between;
  padding: 16px 0;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * + * {
    margin-left: 12px;
  }

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-right: 16px;
  }
}

.shortcutWrapper:last-child:after {
  margin-right: 16px;
}
.icon {
  background-color: $lightBackground;
  border-radius: 9999px;
  position: relative;
  width: 54px;
  height: 54px;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
}

.shortcutText {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: $typo4;
  white-space: nowrap;
}

@include tablet {
  .shortcutSection {
    padding: 40px 0;
  }
  .shortcut {
    flex-direction: column;
    padding: 0 20px;
  }
  .shortcutText {
    margin-left: 0;
    margin-top: 8px;
    font-size: 15px;
  }
}

@include mobile {
  .icon {
    width: 54px;
    height: 54px;
  }
  .shortcutSection {
    padding: 16px;
  }
  .shortcut {
    flex: 1;
    padding: 0px;
  }
  .shortcutText {
    font-size: 13px;
    margin-top: 12px;
    line-height: 100%;
    font-weight: 500;
    color: $gray800
  }
}
.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}